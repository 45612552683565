// 所有的接口地址
import { result } from "lodash"
import request from "../utils/request.js"
// 登录接口
export const Login = (username, pwd, code, uniqid) => {
    return request({
        url: '/login/Login/login',
        method: 'post',
        data: {
            mobile: username,
            pwd: pwd,
            code: code,
            uniqid: uniqid
        }
    })
}

// 二维码接口
export const getCode = () => {
    return request({
        url: '/login/Login/verify',
        method: 'get'
    })
}

// 删除学期的数据接口
export const schoolDel = (id, type) => {
    return request({
        url: '/index/index/title_del',
        method: 'get',
        params: {
            id: id,
            type: type
        }
    })
}
//查看学校
export const shoolindex = () => {
    return request({
        url: '/admin/index/index',
        method: 'get',
        params: {
        }
    })
}



//新建学校
export const addshool = (schoolname, school_url, school_id, status) => {
    return request({
        url: '/admin/index/school_add',
        method: 'post',
        data: {
            school_name: schoolname,
            school_url: school_url,
            school_id: school_id,
            status: status

        }
    })
}

//学校新增专栏

export const shoolzhuanlan = (name, zl_url, school_id, model, copyright, color, school_url, zhuanlan_id, logo_url, status) => {
    return request({
        url: '/admin/index/zhuanlan_add',
        method: 'post',
        data: {
            name: name,
            zl_url: zl_url,
            school_id: school_id,
            model: model,
            copyright: copyright,
            color: color,
            school_url: school_url,
            zhuanlan_id: zhuanlan_id,
            logo_url: logo_url,
            status: status
        }
    })
}

//学校专栏 项目大类
export const adzhuanlanpro = (zhuanlan_id, name, type, project_id, status, a_id) => {
    return request({
        url: '/admin/index/project_add',
        method: 'post',
        data: {
            zhuanlan_id: zhuanlan_id,
            name: name,
            type: type,
            project_id: project_id,
            status: status,
            a_id: a_id
        }
    })
}
//查看专栏
export const getzhuanlan = (zhuanlan_id) => {
    return request({
        url: '/admin/index/zhuanlan_index',
        method: 'get',
        params: {
            zhuanlan_id: zhuanlan_id
        }
    })
}

//新增专栏的项目

export const addproject = (zhuanlan_id, name, explain, a_id, project_id, status) => {
    return request({
        url: '/admin/index/project_add',
        method: 'post',
        data: {
            zhuanlan_id: zhuanlan_id,
            name: name,
            explain: explain,
            a_id: a_id,
            project_id: project_id,
            status: status
        }
    })
}

//上传的banner图

export const bannerimg = (name, banner_url, zhuanlan_id, banner_id, status, project_id) => {
    return request({
        url: '/admin/index/banner_add',
        method: 'post',
        data: {
            name: name,
            banner_url: banner_url,
            zhuanlan_id: zhuanlan_id,
            banner_id: banner_id,
            status: status,
            project_id: project_id
        }
    })
}

//获取上传的图片




export const getbanner = (zhuanlan_id, project_id) => {
    return request({
        url: '/admin/index/banner_list',
        method: 'get',
        params: {
            zhuanlan_id: zhuanlan_id,
            project_id: project_id
        }
    })
}

//基础项目
export const getList = (project_id) => {
    return request({
        url: '/admin/Lists/index',
        method: 'get',
        params: {
            project_id: project_id
        }
    })
}


//文件上传设置


export const changeList = (project_id, type, file_type, name, url, pwd, down_url, file_id, status, file_name, file_img, gain_name) => {
    return request({
        url: '/admin/Lists/change_index',
        method: 'post',
        data: {
            project_id: project_id,
            type: type,
            file_type: file_type,
            name: name,
            url: url,
            pwd: pwd,
            down_url: down_url,
            file_id: file_id,
            status: status,
            file_name: file_name,
            file_img: file_img,
            gain_name: gain_name
        }
    })
}

//添加目录文件
export const Listtwice_add = (name, project_id, modular_id, status) => {
    return request({
        url: '/admin/Lists/twice_add',
        method: 'post',
        data: {
            name: name,
            project_id: project_id,
            modular_id: modular_id,
            status: status
        }
    })
}

//查询二级目录

export const Listtwice_listdata = (project_id, modular_id,) => {
    return request({
        url: '/admin/Lists/twice_list',
        method: 'get',
        params: {
            project_id: project_id,
            modular_id: modular_id,
        }
    })
}  

//二级目录新增修改


export const addtwice_change = (name, modular_id, project_id, status, file_id, url, pwd, chird_id, content, type) => {
    return request({
        url: '/admin/Lists/twice_change',
        method: 'post',
        data: {
            name: name,
            modular_id: modular_id,
            project_id: project_id,
            status: status,
            file_id: file_id,
            url: url,
            pwd: pwd,
            chird_id: chird_id,
            content: content,   
            type: type
        }
    })
}



// 获取基本信息
export const zhuanlan_index = (params) => {
    return request({
        url: '/home/index/zhuanlan_index',
        method: 'get',
        params
    })
}
// banner图
export const banner_list = (params) => {
    return request({
        url: 'home/index/banner_list',
        method: 'get',
        params
    })
}
// 获取栏目信息
export const index_list = (params) => {
    return request({
        url: 'home/Lists/index',
        method: 'get',
        params
    })
}
// 获取nav信息
//project_id-modular_id
export const twice_list = (params) => {
    return request({
        url: 'home/Lists/twice_list',
        method: 'get',
        params
    })
}

// 验证码
export const check = (params) => {
    return request({
        url: 'home/Lists/check',
        method: 'get',
        params
    })
}

// 富文本
export const twice_chioce = (params) => {
    return request({
        url: 'home/Lists/twice_chioce',
        method: 'get',
        params
    })
}

//获取二级的目录

export const twice_index = (params) => {
    return request({
        url: 'home/Lists/twice_index',
        method: 'get',
        params
    })
}
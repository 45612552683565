// axios的二次封装
import axios from "axios";
import { message } from './message';
import 'nprogress/nprogress.css'
import qs from 'qs'



const request = axios.create({
    timeout: 5000000,
    // baseURL: '/api'
})

request.interceptors.request.use(function (config) {
    let userId = window.sessionStorage.getItem('userId')
    let tokened = window.sessionStorage.getItem('tokened')
    // console.log(window.sessionStorage.getItem('tokened'));
    // 在请求头中埋下userId参数
    config.headers['token'] = tokened

    console.log(tokened)
    if (userId) {
        config.headers['u_id'] = userId
    }
    // 在get请求中埋下userId的参数
    if (config.method == 'get') {
        config.params = {
            ...config.params,
            u_id: userId,
            token: tokened
        }
    } else if (config.method == 'post') {
        // 在post请求中埋下userId的参数
        config.data = qs.stringify({
            ...config.data,
            u_id: userId,
            token: tokened
        })
    }
    return config;
}, function (err) {
    return Promise.reject(err);
})

request.interceptors.response.use(
    response => {
        if (response.status == 200) {
            return Promise.resolve(response)
        } else {
            message({
                message: '网络出了问题噢',
                type: 'error'
            })
            return Promise.reject(response);
        }
    }, function (error) {
        return Promise.reject(error);
    }
)

export default request;
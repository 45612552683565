import Vue from 'vue'
import Vuex from 'vuex'
// 能让vuex不用刷新页面就消失的插件
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    f5:1,
    pc:true,//pc端
    mob:false,//手机端
    roleSchool:"",//后缀
    roleSchool2:'',//后缀
    roleSchool3:"",//后缀
    roleSchool4:"",//后缀
    themeData:{},//栏目基本信息
    themeOther:{},//栏目基本信息
    title:"",//标题一
    title2:"",//标题二
    dom_title:"",//浏览器标题
    dom_title2:"",//浏览器标题
    dom_title3:"",//浏览器标题
    // evaluatedata: {}      //班级管理评价列表数据
  },
  mutations: {
    cahngf5index(state){
      state.f5=1
    },
    changepc(state) {
    state.pc=true
    state.mob=false
    },
    changemob(state) {
      state.pc=false
      state.mob=true
      },
    CdomTitle(state,value){
      state.dom_title=value
    },
    domTitle2(state,value){
      state.dom_title2=value
    },
    domTitle3(state,value){
      state.dom_title3=value
    },
    changeroleSchool(state,value){
      state.roleSchool=value
    },
    changeroleSchool2(state,value){
      state.roleSchool2=value
    },
    changeroleSchool3(state,value){
      state.roleSchool3=value
    },
    changeroleSchool4(state,value){
      state.roleSchool4=value
    },
    changeOder(state,value){
      state.themeOther=value
    },
    changeData(state,value){
      state.themeData=value
    },
    changef5(state,value){
      state.f5++
    }
    // //班级管理评价列表数据
    // changeevaluatedata(state, val) {
    //   state.evaluatedata = val
    // },

  },
  actions: {

  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.localStorage,
  })]
})



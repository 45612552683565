import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './utils/request'
// 导入字体图标库
// import './assets/font/iconfont.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { message } from '../src/utils/message.js'
//引入echarts图表
import echarts from 'echarts'
//引入字体图标库
import './iconfont/iconfont.css'
import './iconfont/iconfont'
import "./style/default/default.scss"


Vue.config.productionTip = false
Vue.prototype.$http = request

Vue.use(ElementUI)
Vue.prototype.$message = message
//引入echarts图表
Vue.prototype.$echarts = echarts


import { Button, Collapse, CollapseItem ,Icon,Swipe, SwipeItem} from 'vant';
Vue.use(Button).use(Collapse).use(CollapseItem).use(Icon).use(Swipe).use(SwipeItem)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div id="app">
    <div v-if="isPc" class="pc">
      <router-view v-if="isReload"></router-view>
    </div>
    <div v-if="isMobil" class="mob">
      <router-view v-if="isReload"></router-view>
    </div>
  </div>
</template>

<script>
import { zhuanlan_index } from "../src/api/api";
export default {
  mounted() {
    console.log(this.$route);
    // this.go()
    this.editDomTitle()
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isReload: true,
      index: 0,
    };
  },
  methods: {

    //不清楚是什么
    reload() {
      this.isReload = false;
      this.$nextTick(() => {
        this.isReload = true;
      });

    },
    // 是否手机端
    isMobile() {
      let result = window.matchMedia("(min-width:780px)").matches;
      console.log(result);
      if (result) {
        this.$store.commit("changepc");
        this.isPc = true;
        this.isMobil = false;
      } else {
        this.$store.commit("changemob");
        this.isPc = false;
        this.isMobil = true;
      }
    },
    // 跳转获取企业站数据
    go() {
      console.log(this.$route);
      if (this.index < 1) {
        zhuanlan_index({ zl_url: 'zjzl' }).then(
          (res) => {
            console.log(res);
            this.$store.state.themeData = res.data.data;
            this.$store.state.themeOther = res.data.other;
            localStorage.setItem('themeData', JSON.stringify(res.data.data))
            localStorage.setItem('themeOther', JSON.stringify(res.data.other))
            if (this.$store.state.themeOther.model == 'brown') {
              this.$store.commit(changeroleSchool, "zjzl")
            } else {
              this.$store.commit(changeroleSchool2, "zjzl")
            }
          }
        );
      }
    },

    editDomTitle() {
      let domtitle = localStorage.getItem('domtitle')
      let urlL = localStorage.getItem('urlL')
      let urlR = window.location.hash.indexOf(urlL) > -1
      console.log(domtitle, urlR)
      if (domtitle && urlR) {
        document.title = domtitle
      }
    }

  },
  created() {
    // 是否手机端
    this.isMobile();


  },
};
</script>

<style lang="scss">
@import "../src/style/default/default.scss";

.app {
  width: 100%;
  background: red;
  height: 100%;
}

.pc {
  width: 100%;
  height: 100%;
  background: white;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from "../store/index";
import { zhuanlan_index } from "../api/api";

import { Message } from "element-ui";

Vue.use(VueRouter);
let roles = store.state.role;
let roleSchool = store.state.roleSchool;
let roleSchool2 = store.state.roleSchool2;
let roleSchool3 = store.state.roleSchool3;
let roleSchool4 = store.state.roleSchool4;
console.log(roleSchool3);
const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login/login.vue"),
  },
  {
    name: "Admin",
    path: "/Admin",
    component: () => import("../views/SuperAdmin/SuperAdmin.vue"),
  },
  {
    name: "register",
    path: "/register",
    component: () => import("../views/Register/register.vue"),
  },
  //企业站路由
  {
    name: "school",
    path: `/${roleSchool}/one`,
    component: () => import("../schoollunchservice/school.vue"),
    meta: {
      title: store.state.dom_title,
    },
  },
  {
    name: "school",
    path: `/${roleSchool2}/Two`,
    component: () => import("../schoollunchservice/schoolTwo.vue"),
    meta: {
      title: store.state.dom_title,
    },
  },
  {
    name: "school",
    path: `/${roleSchool3}/three`,
    component: () => import("../schoollunchservice/schoolthree.vue"),
    meta: {
      title: store.state.dom_title,
    },
  },
  {
    name: "school",
    path: `/${roleSchool4}/four`,
    component: () => import("../schoollunchservice/schoolfour.vue"),
    meta: {
      title: store.state.dom_title,
    },
  },
  {
    name: "content",
    path: `/${roleSchool}/content`,
    component: () =>
      import("../schoollunchservice/components/schoolcontent.vue"),
    meta: {
      title: store.state.dom_title,
    },
  },
  {
    name: "contentTwo",
    path: `/${roleSchool2}/contentTwo`,
    component: () =>
      import("../schoollunchservice/components/schoolcontentTwo.vue"),
    meta: {
      title: store.state.dom_title,
    },
  },
  {
    name: "contentThree",
    path: `/${roleSchool3}/contentThree`,
    component: () =>
      import("../schoollunchservice/components/schoolcontentThree.vue"),
    meta: {
      title: store.state.dom_title,
    },
  },
  {
    name: "contentfour",
    path: `/${roleSchool4}/contentfour`,
    component: () =>
      import("../schoollunchservice/components/schoolcontentfour.vue"),
    meta: {
      title: store.state.dom_title,
    },
  },
  {
    path: `/${roleSchool2}`,
    component: () =>
      import("../schoollunchservice/components/schoolProjectListTwo.vue"),
    meta: {
      // title: JSON.parse(localStorage.getItem("themeOther")).name
    },
  },
  {
    path: `/${roleSchool}`,
    component: () =>
      import("../schoollunchservice/components/schoolProjectList.vue"),
    meta: {
      // title: JSON.parse(localStorage.getItem("themeOther")).name
    },
  },
  {
    path: `/${roleSchool3}`,
    component: () =>
      import("../schoollunchservice/components/schoolProjectListThree.vue"),
    meta: {
      // title:  JSON.parse(localStorage.getItem("themeOther")).name
    },
  },
  {
    path: `/${roleSchool4}`,
    component: () =>
      import("../schoollunchservice/components/schoolProjectListfour.vue"),
    meta: {
      // title:  JSON.parse(localStorage.getItem("themeOther")).name
    },
  },
  //企业站后台设置路由
  {
    path: "/adminschool", //首页设置学校 设置专栏
    name: "adminschool",
    component: () => import("../schoollunchserviceadmin/adminschool.vue"),
  },
  {
    path: "/adminproject", //首页设置学校 设置项目
    name: "adminproject",
    component: () => import("../schoollunchserviceadmin/adminproject.vue"),
  },
  {
    path: "/adminbasics", //首页设置学校  基础设置页面
    name: "adminbasics",
    component: () => import("../schoollunchserviceadmin/adminbasics.vue"),
  },
  {
    path: "/pdf", //首页设置学校  基础设置页面
    name: "pdf",
    component: () => import("../views/pdf/pdf.vue"),
  },
];

const router = new VueRouter({
  routes,
  //mode:'history'   //去除#号
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
export default router;
let aa = ""; //用来修改进入时后缀多家/
router.beforeEach((to, from, next) => {
  if (to.path[to.path.length - 1] == "/") {
    console.log(to.path);
    aa = to.path.substr(0, to.path.length - 1);
    console.log(aa);
  } else {
    aa = to.path;
  }
  // let aa = to.path.length
  // console.log(aa);
  console.log(to.name);
  store.commit("CdomTitle", to.query.name);
  if (to.name == "Login") {
    next();
  } else if (
    to.name == "adminschool" ||
    to.name == "adminproject" ||
    to.name == "adminbasics"
  ) {
    let token = window.sessionStorage.getItem("tokened");
    if (token) {
      next();
    } else {
      Message.error("请先登录");
      next("/login");
    }
  } else if (!to.name) {
    let surl = aa.substring(1);
    if (surl.indexOf("//") > -1) {
      surl = surl.replace("//", "/");
    }
    console.log(surl);
    console.log(surl.indexOf("toolbar=0"))
    if (surl != 'toolbar=0' && surl != '/toolbar=0' && surl != '') {
      zhuanlan_index({ zl_url: surl }).then((res) => {
        console.log(res.data.other.color, 11111111)
        document
          .getElementsByTagName("body")[0]
          .style.setProperty("--test", res.data.other.color);

        document.title = res.data.data[0].name
        localStorage.setItem('domtitle', res.data.data[0].name)
        if (to.meta && to.meta.title) {
          document.title = to.meta.title;
        }
        store.commit("changeOder", res.data.other);
        store.commit("changeData", res.data.data);
        if (res.data.code == 200) {
          localStorage.setItem("themeData", JSON.stringify(res.data.data));
          localStorage.setItem("themeOther", JSON.stringify(res.data.other));
        }

        if (JSON.parse(localStorage.getItem("themeOther")).model == "brown") {
          // JSON.parse(localStorage.getItem('themeOther')).model == 'brown'

          localStorage.setItem("urlL", surl);
          if (store.state.roleSchool != localStorage.getItem("urlL")) {
            store.commit("cahngf5index");
          }
          store.commit("changeroleSchool", surl);
          store.commit("changeroleSchool2", "");
          store.commit("changeroleSchool3", "");
          store.commit("changeroleSchool4", "");
          console.log(store.state.roleSchool, localStorage.getItem("urlL"));
          console.log("我改变了brown");

          if (store.state.f5 == 1) {
            window.location.reload();
          }
          store.commit("changef5");
          console.log(aa);
          next(aa);
        } else if (
          JSON.parse(localStorage.getItem("themeOther")).model == "blue"
        ) {
          // JSON.parse(localStorage.getItem('themeOther')).model == 'blue'
          localStorage.setItem("urlL", surl);
          if (store.state.roleSchool2 != localStorage.getItem("urlL")) {
            console.log(2);
            store.commit("cahngf5index");
          }
          store.commit("changeroleSchool2", surl);
          store.commit("changeroleSchool", "");
          store.commit("changeroleSchool3", "");
          store.commit("changeroleSchool4", "");
          console.log("我改变了blue");

          if (store.state.f5 == 1) {
            window.location.reload();
          }
          store.commit("changef5");
          console.log(aa);
          next(aa);
        } else if (
          JSON.parse(localStorage.getItem("themeOther")).model == "green"
        ) {
          localStorage.setItem("urlL", surl);
          if (store.state.roleSchool3 != localStorage.getItem("urlL")) {
            store.commit("cahngf5index");
          }
          store.commit("changeroleSchool3", surl);
          store.commit("changeroleSchool", "");
          store.commit("changeroleSchool2", "");
          store.commit("changeroleSchool4", "");
          console.log("我改变了3");

          if (store.state.f5 == 1) {
            window.location.reload();
          }
          store.commit("changef5");
          console.log(aa);
          next(aa);
        } else if (
          JSON.parse(localStorage.getItem("themeOther")).model == "yellow"
        ) {
          // JSON.parse(localStorage.getItem('themeOther')).model == 'yellow'
          localStorage.setItem("urlL", surl);
          if (store.state.roleSchool4 != localStorage.getItem("urlL")) {
            store.commit("cahngf5index");
          }
          store.commit("changeroleSchool4", surl);
          store.commit("changeroleSchool", "");
          store.commit("changeroleSchool2", "");
          store.commit("changeroleSchool3", "");
          console.log("我改变了4");

          if (store.state.f5 == 1) {
            window.location.reload();
          }
          store.commit("changef5");
          console.log(aa);
          next(aa);
        }
      });
    }
  } else {
    if (to.meta && to.meta.title) {
      document.title = to.meta.title;
    }
    store.state.themeData = JSON.parse(localStorage.getItem("themeData"));
    store.state.themeOther = JSON.parse(localStorage.getItem("themeOther"));

    next();
  }
  next();
});

// router.afterEach((to, from) => {
//   NProgress.done()
// })
